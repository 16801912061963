/*noinspection ALL*/

@keyframes snow-load {
	from { opacity: 0; }
	to {opacity: 1; }
}

.snow-bg {
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 50px;
	height: calc(100%);
	overflow: hidden;
	background: radial-gradient(#999999,#393E44);
	z-index: -1;
}

.snow-bg .snow {
	left: -10px;
	position: absolute;
	width: 10px;
	height: 10px;
	background: white;
	border-radius: 50%;
	animation: snow-load 1s;
}
.snow-bg .snow:nth-child(1) {
	opacity: 0.2464;
	transform: translate(26.9676vw, -10px) scale(0.1715);
	animation: fall-1 26s -28s linear infinite;
}
@keyframes fall-1 {
	44.971% {
		transform: translate(25.7378vw, 44.971vh) scale(0.1715);
	}
	to {
		transform: translate(26.3527vw, 100vh) scale(0.1715);
	}
}
.snow-bg .snow-bg.snow-bg .snow:nth-child(2) {
	opacity: 0.8935;
	transform: translate(69.0185vw, -10px) scale(0.8073);
	animation: fall-2 27s -10s linear infinite;
}
@keyframes fall-2 {
	32.929% {
		transform: translate(61.9211vw, 32.929vh) scale(0.8073);
	}
	to {
		transform: translate(65.4698vw, 100vh) scale(0.8073);
	}
}
.snow-bg .snow:nth-child(3) {
	opacity: 0.1993;
	transform: translate(72.4778vw, -10px) scale(0.5905);
	animation: fall-3 27s -29s linear infinite;
}
@keyframes fall-3 {
	34.948% {
		transform: translate(70.0194vw, 34.948vh) scale(0.5905);
	}
	to {
		transform: translate(71.2486vw, 100vh) scale(0.5905);
	}
}
.snow-bg .snow:nth-child(4) {
	opacity: 0.3901;
	transform: translate(38.8565vw, -10px) scale(0.6326);
	animation: fall-4 30s -14s linear infinite;
}
@keyframes fall-4 {
	64.165% {
		transform: translate(48.5853vw, 64.165vh) scale(0.6326);
	}
	to {
		transform: translate(43.7209vw, 100vh) scale(0.6326);
	}
}
.snow-bg .snow:nth-child(5) {
	opacity: 0.9658;
	transform: translate(43.5365vw, -10px) scale(0.1614);
	animation: fall-5 11s -9s linear infinite;
}
@keyframes fall-5 {
	63.254% {
		transform: translate(52.0764vw, 63.254vh) scale(0.1614);
	}
	to {
		transform: translate(47.80645vw, 100vh) scale(0.1614);
	}
}
.snow-bg .snow:nth-child(6) {
	opacity: 0.6249;
	transform: translate(4.3322vw, -10px) scale(0.0039);
	animation: fall-6 13s -13s linear infinite;
}
@keyframes fall-6 {
	35.488% {
		transform: translate(-1.1394vw, 35.488vh) scale(0.0039);
	}
	to {
		transform: translate(1.5964vw, 100vh) scale(0.0039);
	}
}
.snow-bg .snow:nth-child(7) {
	opacity: 0.623;
	transform: translate(52.7346vw, -10px) scale(0.4436);
	animation: fall-7 21s -5s linear infinite;
}
@keyframes fall-7 {
	51.225% {
		transform: translate(51.477vw, 51.225vh) scale(0.4436);
	}
	to {
		transform: translate(52.1058vw, 100vh) scale(0.4436);
	}
}
.snow-bg .snow:nth-child(8) {
	opacity: 0.801;
	transform: translate(19.4164vw, -10px) scale(0.07);
	animation: fall-8 30s -30s linear infinite;
}
@keyframes fall-8 {
	59.749% {
		transform: translate(20.8093vw, 59.749vh) scale(0.07);
	}
	to {
		transform: translate(20.11285vw, 100vh) scale(0.07);
	}
}
.snow-bg .snow:nth-child(9) {
	opacity: 0.4207;
	transform: translate(68.7043vw, -10px) scale(0.7829);
	animation: fall-9 16s -29s linear infinite;
}
@keyframes fall-9 {
	77.631% {
		transform: translate(63.3356vw, 77.631vh) scale(0.7829);
	}
	to {
		transform: translate(66.01995vw, 100vh) scale(0.7829);
	}
}
.snow-bg .snow:nth-child(10) {
	opacity: 0.0085;
	transform: translate(5.2071vw, -10px) scale(0.7268);
	animation: fall-10 12s -5s linear infinite;
}
@keyframes fall-10 {
	56.984% {
		transform: translate(4.3593vw, 56.984vh) scale(0.7268);
	}
	to {
		transform: translate(4.7832vw, 100vh) scale(0.7268);
	}
}
.snow-bg .snow:nth-child(11) {
	opacity: 0.9616;
	transform: translate(53.5865vw, -10px) scale(0.7171);
	animation: fall-11 15s -28s linear infinite;
}
@keyframes fall-11 {
	70.325% {
		transform: translate(56.864vw, 70.325vh) scale(0.7171);
	}
	to {
		transform: translate(55.22525vw, 100vh) scale(0.7171);
	}
}
.snow-bg .snow:nth-child(12) {
	opacity: 0.8441;
	transform: translate(11.0908vw, -10px) scale(0.9696);
	animation: fall-12 17s -26s linear infinite;
}
@keyframes fall-12 {
	67.216% {
		transform: translate(4.5043vw, 67.216vh) scale(0.9696);
	}
	to {
		transform: translate(7.79755vw, 100vh) scale(0.9696);
	}
}
.snow-bg .snow:nth-child(13) {
	opacity: 0.9953;
	transform: translate(40.1608vw, -10px) scale(0.1771);
	animation: fall-13 28s -21s linear infinite;
}
@keyframes fall-13 {
	46.277% {
		transform: translate(47.1685vw, 46.277vh) scale(0.1771);
	}
	to {
		transform: translate(43.66465vw, 100vh) scale(0.1771);
	}
}
.snow-bg .snow:nth-child(14) {
	opacity: 0.1805;
	transform: translate(84.8332vw, -10px) scale(0.3882);
	animation: fall-14 11s -19s linear infinite;
}
@keyframes fall-14 {
	53.443% {
		transform: translate(79.1332vw, 53.443vh) scale(0.3882);
	}
	to {
		transform: translate(81.9832vw, 100vh) scale(0.3882);
	}
}
.snow-bg .snow:nth-child(15) {
	opacity: 0.198;
	transform: translate(33.5987vw, -10px) scale(0.4685);
	animation: fall-15 27s -24s linear infinite;
}
@keyframes fall-15 {
	41.907% {
		transform: translate(26.4181vw, 41.907vh) scale(0.4685);
	}
	to {
		transform: translate(30.0084vw, 100vh) scale(0.4685);
	}
}
.snow-bg .snow:nth-child(16) {
	opacity: 0.9563;
	transform: translate(34.1856vw, -10px) scale(0.7386);
	animation: fall-16 18s -30s linear infinite;
}
@keyframes fall-16 {
	58.922% {
		transform: translate(31.3781vw, 58.922vh) scale(0.7386);
	}
	to {
		transform: translate(32.78185vw, 100vh) scale(0.7386);
	}
}
.snow-bg .snow:nth-child(17) {
	opacity: 0.6403;
	transform: translate(15.6257vw, -10px) scale(0.8088);
	animation: fall-17 19s -6s linear infinite;
}
@keyframes fall-17 {
	58.08% {
		transform: translate(15.325vw, 58.08vh) scale(0.8088);
	}
	to {
		transform: translate(15.47535vw, 100vh) scale(0.8088);
	}
}
.snow-bg .snow:nth-child(18) {
	opacity: 0.4439;
	transform: translate(78.418vw, -10px) scale(0.6709);
	animation: fall-18 24s -20s linear infinite;
}
@keyframes fall-18 {
	58.899% {
		transform: translate(77.2292vw, 58.899vh) scale(0.6709);
	}
	to {
		transform: translate(77.8236vw, 100vh) scale(0.6709);
	}
}
.snow-bg .snow:nth-child(19) {
	opacity: 0.4751;
	transform: translate(80.1558vw, -10px) scale(0.3879);
	animation: fall-19 18s -19s linear infinite;
}
@keyframes fall-19 {
	60.617% {
		transform: translate(79.92vw, 60.617vh) scale(0.3879);
	}
	to {
		transform: translate(80.0379vw, 100vh) scale(0.3879);
	}
}
.snow-bg .snow:nth-child(20) {
	opacity: 0.4286;
	transform: translate(68.3894vw, -10px) scale(0.8781);
	animation: fall-20 13s -15s linear infinite;
}
@keyframes fall-20 {
	42.047% {
		transform: translate(59.2581vw, 42.047vh) scale(0.8781);
	}
	to {
		transform: translate(63.82375vw, 100vh) scale(0.8781);
	}
}
.snow-bg .snow:nth-child(21) {
	opacity: 0.8978;
	transform: translate(62.295vw, -10px) scale(0.137);
	animation: fall-21 30s -25s linear infinite;
}
@keyframes fall-21 {
	36.824% {
		transform: translate(63.9821vw, 36.824vh) scale(0.137);
	}
	to {
		transform: translate(63.13855vw, 100vh) scale(0.137);
	}
}
.snow-bg .snow:nth-child(22) {
	opacity: 0.5472;
	transform: translate(93.3727vw, -10px) scale(0.448);
	animation: fall-22 26s -12s linear infinite;
}
@keyframes fall-22 {
	43.448% {
		transform: translate(96.5431vw, 43.448vh) scale(0.448);
	}
	to {
		transform: translate(94.9579vw, 100vh) scale(0.448);
	}
}
.snow-bg .snow:nth-child(23) {
	opacity: 0.192;
	transform: translate(57.6837vw, -10px) scale(0.9298);
	animation: fall-23 26s -20s linear infinite;
}
@keyframes fall-23 {
	63.976% {
		transform: translate(53.3456vw, 63.976vh) scale(0.9298);
	}
	to {
		transform: translate(55.51465vw, 100vh) scale(0.9298);
	}
}
.snow-bg .snow:nth-child(24) {
	opacity: 0.9923;
	transform: translate(1.337vw, -10px) scale(0.2569);
	animation: fall-24 22s -15s linear infinite;
}
@keyframes fall-24 {
	37.892% {
		transform: translate(5.6218vw, 37.892vh) scale(0.2569);
	}
	to {
		transform: translate(3.4794vw, 100vh) scale(0.2569);
	}
}
.snow-bg .snow:nth-child(25) {
	opacity: 0.9005;
	transform: translate(53.8452vw, -10px) scale(0.8505);
	animation: fall-25 29s -28s linear infinite;
}
@keyframes fall-25 {
	64.079% {
		transform: translate(58.2258vw, 64.079vh) scale(0.8505);
	}
	to {
		transform: translate(56.0355vw, 100vh) scale(0.8505);
	}
}
.snow-bg .snow:nth-child(26) {
	opacity: 0.7722;
	transform: translate(51.7528vw, -10px) scale(0.5768);
	animation: fall-26 29s -3s linear infinite;
}
@keyframes fall-26 {
	69.666% {
		transform: translate(49.3035vw, 69.666vh) scale(0.5768);
	}
	to {
		transform: translate(50.52815vw, 100vh) scale(0.5768);
	}
}
.snow-bg .snow:nth-child(27) {
	opacity: 0.7053;
	transform: translate(95.4024vw, -10px) scale(0.4016);
	animation: fall-27 18s -15s linear infinite;
}
@keyframes fall-27 {
	53.393% {
		transform: translate(100.5583vw, 53.393vh) scale(0.4016);
	}
	to {
		transform: translate(97.98035vw, 100vh) scale(0.4016);
	}
}
.snow-bg .snow:nth-child(28) {
	opacity: 0.1476;
	transform: translate(73.2671vw, -10px) scale(0.4417);
	animation: fall-28 18s -26s linear infinite;
}
@keyframes fall-28 {
	34.583% {
		transform: translate(68.7855vw, 34.583vh) scale(0.4417);
	}
	to {
		transform: translate(71.0263vw, 100vh) scale(0.4417);
	}
}
.snow-bg .snow:nth-child(29) {
	opacity: 0.8309;
	transform: translate(49.1396vw, -10px) scale(0.2804);
	animation: fall-29 14s -26s linear infinite;
}
@keyframes fall-29 {
	42.55% {
		transform: translate(51.6037vw, 42.55vh) scale(0.2804);
	}
	to {
		transform: translate(50.37165vw, 100vh) scale(0.2804);
	}
}
.snow-bg .snow:nth-child(30) {
	opacity: 0.7251;
	transform: translate(9.6017vw, -10px) scale(0.5085);
	animation: fall-30 12s -24s linear infinite;
}
@keyframes fall-30 {
	53.657% {
		transform: translate(14.7821vw, 53.657vh) scale(0.5085);
	}
	to {
		transform: translate(12.1919vw, 100vh) scale(0.5085);
	}
}
.snow-bg .snow:nth-child(31) {
	opacity: 0.8902;
	transform: translate(10.8817vw, -10px) scale(0.2839);
	animation: fall-31 25s -17s linear infinite;
}
@keyframes fall-31 {
	50.477% {
		transform: translate(16.0997vw, 50.477vh) scale(0.2839);
	}
	to {
		transform: translate(13.4907vw, 100vh) scale(0.2839);
	}
}
.snow-bg .snow:nth-child(32) {
	opacity: 0.6999;
	transform: translate(16.8855vw, -10px) scale(0.5134);
	animation: fall-32 10s -11s linear infinite;
}
@keyframes fall-32 {
	60.73% {
		transform: translate(19.0193vw, 60.73vh) scale(0.5134);
	}
	to {
		transform: translate(17.9524vw, 100vh) scale(0.5134);
	}
}
.snow-bg .snow:nth-child(33) {
	opacity: 0.8822;
	transform: translate(55.9595vw, -10px) scale(0.6345);
	animation: fall-33 22s -23s linear infinite;
}
@keyframes fall-33 {
	51.938% {
		transform: translate(52.2221vw, 51.938vh) scale(0.6345);
	}
	to {
		transform: translate(54.0908vw, 100vh) scale(0.6345);
	}
}
.snow-bg .snow:nth-child(34) {
	opacity: 0.2449;
	transform: translate(57.6992vw, -10px) scale(0.3201);
	animation: fall-34 19s -18s linear infinite;
}
@keyframes fall-34 {
	39.795% {
		transform: translate(58.7336vw, 39.795vh) scale(0.3201);
	}
	to {
		transform: translate(58.2164vw, 100vh) scale(0.3201);
	}
}
.snow-bg .snow:nth-child(35) {
	opacity: 0.8699;
	transform: translate(82.5333vw, -10px) scale(0.6189);
	animation: fall-35 22s -2s linear infinite;
}
@keyframes fall-35 {
	41.927% {
		transform: translate(88.9416vw, 41.927vh) scale(0.6189);
	}
	to {
		transform: translate(85.73745vw, 100vh) scale(0.6189);
	}
}
.snow-bg .snow:nth-child(36) {
	opacity: 0.2821;
	transform: translate(83.0137vw, -10px) scale(0.2607);
	animation: fall-36 28s -14s linear infinite;
}
@keyframes fall-36 {
	43.376% {
		transform: translate(79.6244vw, 43.376vh) scale(0.2607);
	}
	to {
		transform: translate(81.31905vw, 100vh) scale(0.2607);
	}
}
.snow-bg .snow:nth-child(37) {
	opacity: 0.0335;
	transform: translate(68.294vw, -10px) scale(0.1812);
	animation: fall-37 25s -13s linear infinite;
}
@keyframes fall-37 {
	35.624% {
		transform: translate(71.7049vw, 35.624vh) scale(0.1812);
	}
	to {
		transform: translate(69.99945vw, 100vh) scale(0.1812);
	}
}
.snow-bg .snow:nth-child(38) {
	opacity: 0.1459;
	transform: translate(69.585vw, -10px) scale(0.4496);
	animation: fall-38 15s -19s linear infinite;
}
@keyframes fall-38 {
	75.97% {
		transform: translate(78.6469vw, 75.97vh) scale(0.4496);
	}
	to {
		transform: translate(74.11595vw, 100vh) scale(0.4496);
	}
}
.snow-bg .snow:nth-child(39) {
	opacity: 0.7938;
	transform: translate(93.5204vw, -10px) scale(0.6858);
	animation: fall-39 13s -5s linear infinite;
}
@keyframes fall-39 {
	55.509% {
		transform: translate(92.6443vw, 55.509vh) scale(0.6858);
	}
	to {
		transform: translate(93.08235vw, 100vh) scale(0.6858);
	}
}
.snow-bg .snow:nth-child(40) {
	opacity: 0.3893;
	transform: translate(28.5138vw, -10px) scale(0.7837);
	animation: fall-40 20s -5s linear infinite;
}
@keyframes fall-40 {
	41.936% {
		transform: translate(18.9319vw, 41.936vh) scale(0.7837);
	}
	to {
		transform: translate(23.72285vw, 100vh) scale(0.7837);
	}
}
.snow-bg .snow:nth-child(41) {
	opacity: 0.7409;
	transform: translate(24.7875vw, -10px) scale(0.9408);
	animation: fall-41 24s -9s linear infinite;
}
@keyframes fall-41 {
	66.914% {
		transform: translate(22.903vw, 66.914vh) scale(0.9408);
	}
	to {
		transform: translate(23.84525vw, 100vh) scale(0.9408);
	}
}
.snow-bg .snow:nth-child(42) {
	opacity: 0.0847;
	transform: translate(63.9144vw, -10px) scale(0.7316);
	animation: fall-42 30s -6s linear infinite;
}
@keyframes fall-42 {
	61.307% {
		transform: translate(61.5413vw, 61.307vh) scale(0.7316);
	}
	to {
		transform: translate(62.72785vw, 100vh) scale(0.7316);
	}
}
.snow-bg .snow:nth-child(43) {
	opacity: 0.3488;
	transform: translate(89.431vw, -10px) scale(0.2794);
	animation: fall-43 28s -22s linear infinite;
}
@keyframes fall-43 {
	58.473% {
		transform: translate(91.579vw, 58.473vh) scale(0.2794);
	}
	to {
		transform: translate(90.505vw, 100vh) scale(0.2794);
	}
}
.snow-bg .snow:nth-child(44) {
	opacity: 0.1988;
	transform: translate(76.3335vw, -10px) scale(0.9549);
	animation: fall-44 23s -2s linear infinite;
}
@keyframes fall-44 {
	78.28% {
		transform: translate(78.6231vw, 78.28vh) scale(0.9549);
	}
	to {
		transform: translate(77.4783vw, 100vh) scale(0.9549);
	}
}
.snow-bg .snow:nth-child(45) {
	opacity: 0.915;
	transform: translate(70.7838vw, -10px) scale(0.7909);
	animation: fall-45 30s -27s linear infinite;
}
@keyframes fall-45 {
	35.858% {
		transform: translate(67.6307vw, 35.858vh) scale(0.7909);
	}
	to {
		transform: translate(69.20725vw, 100vh) scale(0.7909);
	}
}
.snow-bg .snow:nth-child(46) {
	opacity: 0.9753;
	transform: translate(18.6451vw, -10px) scale(0.3889);
	animation: fall-46 25s -16s linear infinite;
}
@keyframes fall-46 {
	62.585% {
		transform: translate(23.6296vw, 62.585vh) scale(0.3889);
	}
	to {
		transform: translate(21.13735vw, 100vh) scale(0.3889);
	}
}
.snow-bg .snow:nth-child(47) {
	opacity: 0.9367;
	transform: translate(65.5267vw, -10px) scale(0.0161);
	animation: fall-47 26s -1s linear infinite;
}
@keyframes fall-47 {
	48.9% {
		transform: translate(63.841vw, 48.9vh) scale(0.0161);
	}
	to {
		transform: translate(64.68385vw, 100vh) scale(0.0161);
	}
}
.snow-bg .snow:nth-child(48) {
	opacity: 0.9234;
	transform: translate(62.9766vw, -10px) scale(0.7051);
	animation: fall-48 28s -16s linear infinite;
}
@keyframes fall-48 {
	39.276% {
		transform: translate(53.6365vw, 39.276vh) scale(0.7051);
	}
	to {
		transform: translate(58.30655vw, 100vh) scale(0.7051);
	}
}
.snow-bg .snow:nth-child(49) {
	opacity: 0.896;
	transform: translate(50.4959vw, -10px) scale(0.9405);
	animation: fall-49 24s -19s linear infinite;
}
@keyframes fall-49 {
	49.499% {
		transform: translate(58.5044vw, 49.499vh) scale(0.9405);
	}
	to {
		transform: translate(54.50015vw, 100vh) scale(0.9405);
	}
}
.snow-bg .snow:nth-child(50) {
	opacity: 0.7194;
	transform: translate(95.6761vw, -10px) scale(0.0685);
	animation: fall-50 21s -24s linear infinite;
}
@keyframes fall-50 {
	53.688% {
		transform: translate(100.212vw, 53.688vh) scale(0.0685);
	}
	to {
		transform: translate(97.94405vw, 100vh) scale(0.0685);
	}
}
.snow-bg .snow:nth-child(51) {
	opacity: 0.0366;
	transform: translate(76.5153vw, -10px) scale(0.7098);
	animation: fall-51 22s -26s linear infinite;
}
@keyframes fall-51 {
	36.077% {
		transform: translate(77.0969vw, 36.077vh) scale(0.7098);
	}
	to {
		transform: translate(76.8061vw, 100vh) scale(0.7098);
	}
}
.snow-bg .snow:nth-child(52) {
	opacity: 0.4902;
	transform: translate(27.8551vw, -10px) scale(0.7487);
	animation: fall-52 16s -15s linear infinite;
}
@keyframes fall-52 {
	62.766% {
		transform: translate(25.3459vw, 62.766vh) scale(0.7487);
	}
	to {
		transform: translate(26.6005vw, 100vh) scale(0.7487);
	}
}
.snow-bg .snow:nth-child(53) {
	opacity: 0.806;
	transform: translate(74.1379vw, -10px) scale(0.2162);
	animation: fall-53 27s -26s linear infinite;
}
@keyframes fall-53 {
	47.511% {
		transform: translate(73.5078vw, 47.511vh) scale(0.2162);
	}
	to {
		transform: translate(73.82285vw, 100vh) scale(0.2162);
	}
}
.snow-bg .snow:nth-child(54) {
	opacity: 0.5899;
	transform: translate(85.5801vw, -10px) scale(0.1073);
	animation: fall-54 12s -19s linear infinite;
}
@keyframes fall-54 {
	69.907% {
		transform: translate(78.5176vw, 69.907vh) scale(0.1073);
	}
	to {
		transform: translate(82.04885vw, 100vh) scale(0.1073);
	}
}
.snow-bg .snow:nth-child(55) {
	opacity: 0.0512;
	transform: translate(5.7778vw, -10px) scale(0.4551);
	animation: fall-55 19s -26s linear infinite;
}
@keyframes fall-55 {
	58.519% {
		transform: translate(1.9756vw, 58.519vh) scale(0.4551);
	}
	to {
		transform: translate(3.8767vw, 100vh) scale(0.4551);
	}
}
.snow-bg .snow:nth-child(56) {
	opacity: 0.4727;
	transform: translate(27.3699vw, -10px) scale(0.9021);
	animation: fall-56 21s -24s linear infinite;
}
@keyframes fall-56 {
	79.275% {
		transform: translate(30.389vw, 79.275vh) scale(0.9021);
	}
	to {
		transform: translate(28.87945vw, 100vh) scale(0.9021);
	}
}
.snow-bg .snow:nth-child(57) {
	opacity: 0.0201;
	transform: translate(47.5339vw, -10px) scale(0.5023);
	animation: fall-57 28s -13s linear infinite;
}
@keyframes fall-57 {
	46.697% {
		transform: translate(42.1054vw, 46.697vh) scale(0.5023);
	}
	to {
		transform: translate(44.81965vw, 100vh) scale(0.5023);
	}
}
.snow-bg .snow:nth-child(58) {
	opacity: 0.8649;
	transform: translate(8.7222vw, -10px) scale(0.8219);
	animation: fall-58 15s -27s linear infinite;
}
@keyframes fall-58 {
	45.271% {
		transform: translate(15.1531vw, 45.271vh) scale(0.8219);
	}
	to {
		transform: translate(11.93765vw, 100vh) scale(0.8219);
	}
}
.snow-bg .snow:nth-child(59) {
	opacity: 0.0914;
	transform: translate(77.324vw, -10px) scale(0.6946);
	animation: fall-59 15s -8s linear infinite;
}
@keyframes fall-59 {
	41.321% {
		transform: translate(80.8192vw, 41.321vh) scale(0.6946);
	}
	to {
		transform: translate(79.0716vw, 100vh) scale(0.6946);
	}
}
.snow-bg .snow:nth-child(60) {
	opacity: 0.8568;
	transform: translate(40.864vw, -10px) scale(0.9367);
	animation: fall-60 10s -6s linear infinite;
}
@keyframes fall-60 {
	36.409% {
		transform: translate(32.7302vw, 36.409vh) scale(0.9367);
	}
	to {
		transform: translate(36.7971vw, 100vh) scale(0.9367);
	}
}
.snow-bg .snow:nth-child(61) {
	opacity: 0.1227;
	transform: translate(60.8076vw, -10px) scale(0.4783);
	animation: fall-61 24s -14s linear infinite;
}
@keyframes fall-61 {
	48.353% {
		transform: translate(55.0517vw, 48.353vh) scale(0.4783);
	}
	to {
		transform: translate(57.92965vw, 100vh) scale(0.4783);
	}
}
.snow-bg .snow:nth-child(62) {
	opacity: 0.7334;
	transform: translate(58.626vw, -10px) scale(0.6105);
	animation: fall-62 18s -11s linear infinite;
}
@keyframes fall-62 {
	63.49% {
		transform: translate(52.1704vw, 63.49vh) scale(0.6105);
	}
	to {
		transform: translate(55.3982vw, 100vh) scale(0.6105);
	}
}
.snow-bg .snow:nth-child(63) {
	opacity: 0.6104;
	transform: translate(49.488vw, -10px) scale(0.6028);
	animation: fall-63 24s -28s linear infinite;
}
@keyframes fall-63 {
	45.438% {
		transform: translate(51.357vw, 45.438vh) scale(0.6028);
	}
	to {
		transform: translate(50.4225vw, 100vh) scale(0.6028);
	}
}
.snow-bg .snow:nth-child(64) {
	opacity: 0.712;
	transform: translate(31.3495vw, -10px) scale(0.6718);
	animation: fall-64 25s -1s linear infinite;
}
@keyframes fall-64 {
	61.218% {
		transform: translate(26.1286vw, 61.218vh) scale(0.6718);
	}
	to {
		transform: translate(28.73905vw, 100vh) scale(0.6718);
	}
}
.snow-bg .snow:nth-child(65) {
	opacity: 0.7194;
	transform: translate(22.3451vw, -10px) scale(0.5152);
	animation: fall-65 13s -23s linear infinite;
}
@keyframes fall-65 {
	48.263% {
		transform: translate(14.3224vw, 48.263vh) scale(0.5152);
	}
	to {
		transform: translate(18.33375vw, 100vh) scale(0.5152);
	}
}
.snow-bg .snow:nth-child(66) {
	opacity: 0.1012;
	transform: translate(14.7136vw, -10px) scale(0.3801);
	animation: fall-66 19s -5s linear infinite;
}
@keyframes fall-66 {
	72.534% {
		transform: translate(19.7942vw, 72.534vh) scale(0.3801);
	}
	to {
		transform: translate(17.2539vw, 100vh) scale(0.3801);
	}
}
.snow-bg .snow:nth-child(67) {
	opacity: 0.5914;
	transform: translate(74.0203vw, -10px) scale(0.9469);
	animation: fall-67 10s -4s linear infinite;
}
@keyframes fall-67 {
	67.91% {
		transform: translate(70.9579vw, 67.91vh) scale(0.9469);
	}
	to {
		transform: translate(72.4891vw, 100vh) scale(0.9469);
	}
}
.snow-bg .snow:nth-child(68) {
	opacity: 0.7279;
	transform: translate(44.0873vw, -10px) scale(0.0609);
	animation: fall-68 24s -27s linear infinite;
}
@keyframes fall-68 {
	37.876% {
		transform: translate(45.3875vw, 37.876vh) scale(0.0609);
	}
	to {
		transform: translate(44.7374vw, 100vh) scale(0.0609);
	}
}
.snow-bg .snow:nth-child(69) {
	opacity: 0.8239;
	transform: translate(16.3335vw, -10px) scale(0.5144);
	animation: fall-69 28s -11s linear infinite;
}
@keyframes fall-69 {
	47.132% {
		transform: translate(21.0878vw, 47.132vh) scale(0.5144);
	}
	to {
		transform: translate(18.71065vw, 100vh) scale(0.5144);
	}
}
.snow-bg .snow:nth-child(70) {
	opacity: 0.804;
	transform: translate(89.1258vw, -10px) scale(0.7028);
	animation: fall-70 19s -10s linear infinite;
}
@keyframes fall-70 {
	56.606% {
		transform: translate(81.0966vw, 56.606vh) scale(0.7028);
	}
	to {
		transform: translate(85.1112vw, 100vh) scale(0.7028);
	}
}
.snow-bg .snow:nth-child(71) {
	opacity: 0.8756;
	transform: translate(30.7803vw, -10px) scale(0.7647);
	animation: fall-71 11s -3s linear infinite;
}
@keyframes fall-71 {
	65.363% {
		transform: translate(31.5757vw, 65.363vh) scale(0.7647);
	}
	to {
		transform: translate(31.178vw, 100vh) scale(0.7647);
	}
}
.snow-bg .snow:nth-child(72) {
	opacity: 0.8955;
	transform: translate(73.0274vw, -10px) scale(0.0415);
	animation: fall-72 12s -24s linear infinite;
}
@keyframes fall-72 {
	47.205% {
		transform: translate(67.262vw, 47.205vh) scale(0.0415);
	}
	to {
		transform: translate(70.1447vw, 100vh) scale(0.0415);
	}
}
.snow-bg .snow:nth-child(73) {
	opacity: 0.0101;
	transform: translate(81.2947vw, -10px) scale(0.1501);
	animation: fall-73 23s -7s linear infinite;
}
@keyframes fall-73 {
	61.079% {
		transform: translate(74.7104vw, 61.079vh) scale(0.1501);
	}
	to {
		transform: translate(78.00255vw, 100vh) scale(0.1501);
	}
}
.snow-bg .snow:nth-child(74) {
	opacity: 0.4487;
	transform: translate(49.7012vw, -10px) scale(0.5609);
	animation: fall-74 30s -13s linear infinite;
}
@keyframes fall-74 {
	65.448% {
		transform: translate(59.3993vw, 65.448vh) scale(0.5609);
	}
	to {
		transform: translate(54.55025vw, 100vh) scale(0.5609);
	}
}
.snow-bg .snow:nth-child(75) {
	opacity: 0.2535;
	transform: translate(26.8075vw, -10px) scale(0.4149);
	animation: fall-75 23s -3s linear infinite;
}
@keyframes fall-75 {
	57.47% {
		transform: translate(21.0771vw, 57.47vh) scale(0.4149);
	}
	to {
		transform: translate(23.9423vw, 100vh) scale(0.4149);
	}
}
.snow-bg .snow:nth-child(76) {
	opacity: 0.8493;
	transform: translate(49.2783vw, -10px) scale(0.0147);
	animation: fall-76 13s -27s linear infinite;
}
@keyframes fall-76 {
	44.294% {
		transform: translate(40.1668vw, 44.294vh) scale(0.0147);
	}
	to {
		transform: translate(44.72255vw, 100vh) scale(0.0147);
	}
}
.snow-bg .snow:nth-child(77) {
	opacity: 0.2382;
	transform: translate(31.8003vw, -10px) scale(0.3895);
	animation: fall-77 27s -24s linear infinite;
}
@keyframes fall-77 {
	73.989% {
		transform: translate(40.3594vw, 73.989vh) scale(0.3895);
	}
	to {
		transform: translate(36.07985vw, 100vh) scale(0.3895);
	}
}
.snow-bg .snow:nth-child(78) {
	opacity: 0.7452;
	transform: translate(40.8561vw, -10px) scale(0.215);
	animation: fall-78 16s -12s linear infinite;
}
@keyframes fall-78 {
	37.084% {
		transform: translate(39.9006vw, 37.084vh) scale(0.215);
	}
	to {
		transform: translate(40.37835vw, 100vh) scale(0.215);
	}
}
.snow-bg .snow:nth-child(79) {
	opacity: 0.8443;
	transform: translate(85.1938vw, -10px) scale(0.941);
	animation: fall-79 27s -7s linear infinite;
}
@keyframes fall-79 {
	38.108% {
		transform: translate(77.4109vw, 38.108vh) scale(0.941);
	}
	to {
		transform: translate(81.30235vw, 100vh) scale(0.941);
	}
}
.snow-bg .snow:nth-child(80) {
	opacity: 0.4141;
	transform: translate(9.9708vw, -10px) scale(0.906);
	animation: fall-80 26s -7s linear infinite;
}
@keyframes fall-80 {
	58.708% {
		transform: translate(5.7609vw, 58.708vh) scale(0.906);
	}
	to {
		transform: translate(7.86585vw, 100vh) scale(0.906);
	}
}
.snow-bg .snow:nth-child(81) {
	opacity: 0.9721;
	transform: translate(65.3708vw, -10px) scale(0.2077);
	animation: fall-81 12s -13s linear infinite;
}
@keyframes fall-81 {
	72.112% {
		transform: translate(55.877vw, 72.112vh) scale(0.2077);
	}
	to {
		transform: translate(60.6239vw, 100vh) scale(0.2077);
	}
}
.snow-bg .snow:nth-child(82) {
	opacity: 0.529;
	transform: translate(19.1284vw, -10px) scale(0.3292);
	animation: fall-82 29s -24s linear infinite;
}
@keyframes fall-82 {
	40.712% {
		transform: translate(27.2825vw, 40.712vh) scale(0.3292);
	}
	to {
		transform: translate(23.20545vw, 100vh) scale(0.3292);
	}
}
.snow-bg .snow:nth-child(83) {
	opacity: 0.144;
	transform: translate(82.4932vw, -10px) scale(0.9275);
	animation: fall-83 28s -22s linear infinite;
}
@keyframes fall-83 {
	69.054% {
		transform: translate(81.0662vw, 69.054vh) scale(0.9275);
	}
	to {
		transform: translate(81.7797vw, 100vh) scale(0.9275);
	}
}
.snow-bg .snow:nth-child(84) {
	opacity: 0.3276;
	transform: translate(97.1674vw, -10px) scale(0.9585);
	animation: fall-84 17s -2s linear infinite;
}
@keyframes fall-84 {
	69.178% {
		transform: translate(90.1864vw, 69.178vh) scale(0.9585);
	}
	to {
		transform: translate(93.6769vw, 100vh) scale(0.9585);
	}
}
.snow-bg .snow:nth-child(85) {
	opacity: 0.8668;
	transform: translate(66.9948vw, -10px) scale(0.3656);
	animation: fall-85 20s -13s linear infinite;
}
@keyframes fall-85 {
	71.31% {
		transform: translate(67.1147vw, 71.31vh) scale(0.3656);
	}
	to {
		transform: translate(67.05475vw, 100vh) scale(0.3656);
	}
}
.snow-bg .snow:nth-child(86) {
	opacity: 0.2876;
	transform: translate(37.6084vw, -10px) scale(0.3737);
	animation: fall-86 28s -17s linear infinite;
}
@keyframes fall-86 {
	47.737% {
		transform: translate(46.5422vw, 47.737vh) scale(0.3737);
	}
	to {
		transform: translate(42.0753vw, 100vh) scale(0.3737);
	}
}
.snow-bg .snow:nth-child(87) {
	opacity: 0.9928;
	transform: translate(92.99vw, -10px) scale(0.914);
	animation: fall-87 29s -28s linear infinite;
}
@keyframes fall-87 {
	78.052% {
		transform: translate(83.3964vw, 78.052vh) scale(0.914);
	}
	to {
		transform: translate(88.1932vw, 100vh) scale(0.914);
	}
}
.snow-bg .snow:nth-child(88) {
	opacity: 0.9183;
	transform: translate(52.1244vw, -10px) scale(0.2308);
	animation: fall-88 30s -20s linear infinite;
}
@keyframes fall-88 {
	69.672% {
		transform: translate(44.3947vw, 69.672vh) scale(0.2308);
	}
	to {
		transform: translate(48.25955vw, 100vh) scale(0.2308);
	}
}
.snow-bg .snow:nth-child(89) {
	opacity: 0.8957;
	transform: translate(77.8485vw, -10px) scale(0.2893);
	animation: fall-89 30s -14s linear infinite;
}
@keyframes fall-89 {
	38.464% {
		transform: translate(73.5702vw, 38.464vh) scale(0.2893);
	}
	to {
		transform: translate(75.70935vw, 100vh) scale(0.2893);
	}
}
.snow-bg .snow:nth-child(90) {
	opacity: 0.0375;
	transform: translate(94.2573vw, -10px) scale(0.4014);
	animation: fall-90 25s -26s linear infinite;
}
@keyframes fall-90 {
	76.089% {
		transform: translate(89.7681vw, 76.089vh) scale(0.4014);
	}
	to {
		transform: translate(92.0127vw, 100vh) scale(0.4014);
	}
}
.snow-bg .snow:nth-child(91) {
	opacity: 0.0232;
	transform: translate(39.3482vw, -10px) scale(0.7233);
	animation: fall-91 30s -10s linear infinite;
}
@keyframes fall-91 {
	52.28% {
		transform: translate(32.3481vw, 52.28vh) scale(0.7233);
	}
	to {
		transform: translate(35.84815vw, 100vh) scale(0.7233);
	}
}
.snow-bg .snow:nth-child(92) {
	opacity: 0.1493;
	transform: translate(19.0274vw, -10px) scale(0.1514);
	animation: fall-92 15s -2s linear infinite;
}
@keyframes fall-92 {
	45.376% {
		transform: translate(11.3183vw, 45.376vh) scale(0.1514);
	}
	to {
		transform: translate(15.17285vw, 100vh) scale(0.1514);
	}
}
.snow-bg .snow:nth-child(93) {
	opacity: 0.7963;
	transform: translate(5.5574vw, -10px) scale(0.4435);
	animation: fall-93 27s -4s linear infinite;
}
@keyframes fall-93 {
	69.945% {
		transform: translate(13.0367vw, 69.945vh) scale(0.4435);
	}
	to {
		transform: translate(9.29705vw, 100vh) scale(0.4435);
	}
}
.snow-bg .snow:nth-child(94) {
	opacity: 0.4571;
	transform: translate(9.5213vw, -10px) scale(0.404);
	animation: fall-94 17s -15s linear infinite;
}
@keyframes fall-94 {
	52.734% {
		transform: translate(2.7736vw, 52.734vh) scale(0.404);
	}
	to {
		transform: translate(6.14745vw, 100vh) scale(0.404);
	}
}
.snow-bg .snow:nth-child(95) {
	opacity: 0.4629;
	transform: translate(65.819vw, -10px) scale(0.1578);
	animation: fall-95 25s -19s linear infinite;
}
@keyframes fall-95 {
	51.121% {
		transform: translate(71.8894vw, 51.121vh) scale(0.1578);
	}
	to {
		transform: translate(68.8542vw, 100vh) scale(0.1578);
	}
}
.snow-bg .snow:nth-child(96) {
	opacity: 0.4094;
	transform: translate(47.3377vw, -10px) scale(0.2345);
	animation: fall-96 29s -1s linear infinite;
}
@keyframes fall-96 {
	53.276% {
		transform: translate(41.72vw, 53.276vh) scale(0.2345);
	}
	to {
		transform: translate(44.52885vw, 100vh) scale(0.2345);
	}
}
.snow-bg .snow:nth-child(97) {
	opacity: 0.51;
	transform: translate(6.4458vw, -10px) scale(0.1608);
	animation: fall-97 19s -14s linear infinite;
}
@keyframes fall-97 {
	41.632% {
		transform: translate(8.9683vw, 41.632vh) scale(0.1608);
	}
	to {
		transform: translate(7.70705vw, 100vh) scale(0.1608);
	}
}
.snow-bg .snow:nth-child(98) {
	opacity: 0.9025;
	transform: translate(94.521vw, -10px) scale(0.6418);
	animation: fall-98 15s -14s linear infinite;
}
@keyframes fall-98 {
	31.457% {
		transform: translate(96.3005vw, 31.457vh) scale(0.6418);
	}
	to {
		transform: translate(95.41075vw, 100vh) scale(0.6418);
	}
}
.snow-bg .snow:nth-child(99) {
	opacity: 0.0544;
	transform: translate(12.8716vw, -10px) scale(0.5539);
	animation: fall-99 16s -18s linear infinite;
}
@keyframes fall-99 {
	57.551% {
		transform: translate(18.0784vw, 57.551vh) scale(0.5539);
	}
	to {
		transform: translate(15.475vw, 100vh) scale(0.5539);
	}
}
.snow-bg .snow:nth-child(100) {
	opacity: 0.1078;
	transform: translate(5.69vw, -10px) scale(0.3611);
	animation: fall-100 15s -14s linear infinite;
}
@keyframes fall-100 {
	65.421% {
		transform: translate(0.3311vw, 65.421vh) scale(0.3611);
	}
	to {
		transform: translate(3.01055vw, 100vh) scale(0.3611);
	}
}
.snow-bg .snow:nth-child(101) {
	opacity: 0.9244;
	transform: translate(85.355vw, -10px) scale(0.8858);
	animation: fall-101 29s -2s linear infinite;
}
@keyframes fall-101 {
	58.914% {
		transform: translate(88.257vw, 58.914vh) scale(0.8858);
	}
	to {
		transform: translate(86.806vw, 100vh) scale(0.8858);
	}
}
.snow-bg .snow:nth-child(102) {
	opacity: 0.937;
	transform: translate(13.5718vw, -10px) scale(0.2707);
	animation: fall-102 18s -10s linear infinite;
}
@keyframes fall-102 {
	52.084% {
		transform: translate(13.0425vw, 52.084vh) scale(0.2707);
	}
	to {
		transform: translate(13.30715vw, 100vh) scale(0.2707);
	}
}
.snow-bg .snow:nth-child(103) {
	opacity: 0.4882;
	transform: translate(77.7373vw, -10px) scale(0.1697);
	animation: fall-103 17s -8s linear infinite;
}
@keyframes fall-103 {
	75.469% {
		transform: translate(74.3451vw, 75.469vh) scale(0.1697);
	}
	to {
		transform: translate(76.0412vw, 100vh) scale(0.1697);
	}
}
.snow-bg .snow:nth-child(104) {
	opacity: 0.323;
	transform: translate(77.2625vw, -10px) scale(0.5413);
	animation: fall-104 28s -25s linear infinite;
}
@keyframes fall-104 {
	71.805% {
		transform: translate(82.7032vw, 71.805vh) scale(0.5413);
	}
	to {
		transform: translate(79.98285vw, 100vh) scale(0.5413);
	}
}
.snow-bg .snow:nth-child(105) {
	opacity: 0.2274;
	transform: translate(78.0186vw, -10px) scale(0.0771);
	animation: fall-105 27s -15s linear infinite;
}
@keyframes fall-105 {
	36.067% {
		transform: translate(78.4596vw, 36.067vh) scale(0.0771);
	}
	to {
		transform: translate(78.2391vw, 100vh) scale(0.0771);
	}
}
.snow-bg .snow:nth-child(106) {
	opacity: 0.9234;
	transform: translate(65.4661vw, -10px) scale(0.132);
	animation: fall-106 18s -29s linear infinite;
}
@keyframes fall-106 {
	45.77% {
		transform: translate(74.6447vw, 45.77vh) scale(0.132);
	}
	to {
		transform: translate(70.0554vw, 100vh) scale(0.132);
	}
}
.snow-bg .snow:nth-child(107) {
	opacity: 0.3618;
	transform: translate(12.0243vw, -10px) scale(0.8505);
	animation: fall-107 28s -14s linear infinite;
}
@keyframes fall-107 {
	66.746% {
		transform: translate(21.611vw, 66.746vh) scale(0.8505);
	}
	to {
		transform: translate(16.81765vw, 100vh) scale(0.8505);
	}
}
.snow-bg .snow:nth-child(108) {
	opacity: 0.7323;
	transform: translate(65.4547vw, -10px) scale(0.6466);
	animation: fall-108 10s -9s linear infinite;
}
@keyframes fall-108 {
	71.8% {
		transform: translate(74.365vw, 71.8vh) scale(0.6466);
	}
	to {
		transform: translate(69.90985vw, 100vh) scale(0.6466);
	}
}
.snow-bg .snow:nth-child(109) {
	opacity: 0.6477;
	transform: translate(65.9536vw, -10px) scale(0.3216);
	animation: fall-109 27s -12s linear infinite;
}
@keyframes fall-109 {
	40.351% {
		transform: translate(60.1205vw, 40.351vh) scale(0.3216);
	}
	to {
		transform: translate(63.03705vw, 100vh) scale(0.3216);
	}
}
.snow-bg .snow:nth-child(110) {
	opacity: 0.2829;
	transform: translate(83.623vw, -10px) scale(0.8799);
	animation: fall-110 30s -17s linear infinite;
}
@keyframes fall-110 {
	58.309% {
		transform: translate(82.4863vw, 58.309vh) scale(0.8799);
	}
	to {
		transform: translate(83.05465vw, 100vh) scale(0.8799);
	}
}
.snow-bg .snow:nth-child(111) {
	opacity: 0.236;
	transform: translate(33.6542vw, -10px) scale(0.0245);
	animation: fall-111 17s -8s linear infinite;
}
@keyframes fall-111 {
	46.685% {
		transform: translate(37.024vw, 46.685vh) scale(0.0245);
	}
	to {
		transform: translate(35.3391vw, 100vh) scale(0.0245);
	}
}
.snow-bg .snow:nth-child(112) {
	opacity: 0.6768;
	transform: translate(64.8247vw, -10px) scale(0.2798);
	animation: fall-112 29s -18s linear infinite;
}
@keyframes fall-112 {
	40.705% {
		transform: translate(55.7088vw, 40.705vh) scale(0.2798);
	}
	to {
		transform: translate(60.26675vw, 100vh) scale(0.2798);
	}
}
.snow-bg .snow:nth-child(113) {
	opacity: 0.0598;
	transform: translate(25.9633vw, -10px) scale(0.3225);
	animation: fall-113 23s -10s linear infinite;
}
@keyframes fall-113 {
	46.797% {
		transform: translate(32.27vw, 46.797vh) scale(0.3225);
	}
	to {
		transform: translate(29.11665vw, 100vh) scale(0.3225);
	}
}
.snow-bg .snow:nth-child(114) {
	opacity: 0.9463;
	transform: translate(47.6087vw, -10px) scale(0.6941);
	animation: fall-114 23s -8s linear infinite;
}
@keyframes fall-114 {
	44.687% {
		transform: translate(50.4253vw, 44.687vh) scale(0.6941);
	}
	to {
		transform: translate(49.017vw, 100vh) scale(0.6941);
	}
}
.snow-bg .snow:nth-child(115) {
	opacity: 0.1929;
	transform: translate(79.5101vw, -10px) scale(0.3792);
	animation: fall-115 25s -10s linear infinite;
}
@keyframes fall-115 {
	31.002% {
		transform: translate(76.3526vw, 31.002vh) scale(0.3792);
	}
	to {
		transform: translate(77.93135vw, 100vh) scale(0.3792);
	}
}
.snow-bg .snow:nth-child(116) {
	opacity: 0.182;
	transform: translate(4.0585vw, -10px) scale(0.5114);
	animation: fall-116 13s -1s linear infinite;
}
@keyframes fall-116 {
	31.012% {
		transform: translate(3.0743vw, 31.012vh) scale(0.5114);
	}
	to {
		transform: translate(3.5664vw, 100vh) scale(0.5114);
	}
}
.snow-bg .snow:nth-child(117) {
	opacity: 0.8003;
	transform: translate(36.3166vw, -10px) scale(0.5997);
	animation: fall-117 26s -1s linear infinite;
}
@keyframes fall-117 {
	63.008% {
		transform: translate(38.3545vw, 63.008vh) scale(0.5997);
	}
	to {
		transform: translate(37.33555vw, 100vh) scale(0.5997);
	}
}
.snow-bg .snow:nth-child(118) {
	opacity: 0.9647;
	transform: translate(74.6717vw, -10px) scale(0.7611);
	animation: fall-118 11s -20s linear infinite;
}
@keyframes fall-118 {
	68.644% {
		transform: translate(80.8568vw, 68.644vh) scale(0.7611);
	}
	to {
		transform: translate(77.76425vw, 100vh) scale(0.7611);
	}
}
.snow-bg .snow:nth-child(119) {
	opacity: 0.2137;
	transform: translate(56.5148vw, -10px) scale(0.4012);
	animation: fall-119 28s -9s linear infinite;
}
@keyframes fall-119 {
	73.668% {
		transform: translate(53.8429vw, 73.668vh) scale(0.4012);
	}
	to {
		transform: translate(55.17885vw, 100vh) scale(0.4012);
	}
}
.snow-bg .snow:nth-child(120) {
	opacity: 0.8212;
	transform: translate(77.4804vw, -10px) scale(0.4672);
	animation: fall-120 19s -12s linear infinite;
}
@keyframes fall-120 {
	69.664% {
		transform: translate(87.1855vw, 69.664vh) scale(0.4672);
	}
	to {
		transform: translate(82.33295vw, 100vh) scale(0.4672);
	}
}
.snow-bg .snow:nth-child(121) {
	opacity: 0.5108;
	transform: translate(26.8313vw, -10px) scale(0.7717);
	animation: fall-121 23s -19s linear infinite;
}
@keyframes fall-121 {
	63.122% {
		transform: translate(19.7775vw, 63.122vh) scale(0.7717);
	}
	to {
		transform: translate(23.3044vw, 100vh) scale(0.7717);
	}
}
.snow-bg .snow:nth-child(122) {
	opacity: 0.9729;
	transform: translate(97.8455vw, -10px) scale(0.4066);
	animation: fall-122 24s -23s linear infinite;
}
@keyframes fall-122 {
	76.325% {
		transform: translate(94.8655vw, 76.325vh) scale(0.4066);
	}
	to {
		transform: translate(96.3555vw, 100vh) scale(0.4066);
	}
}
.snow-bg .snow:nth-child(123) {
	opacity: 0.1368;
	transform: translate(91.8582vw, -10px) scale(0.4796);
	animation: fall-123 16s -5s linear infinite;
}
@keyframes fall-123 {
	77.106% {
		transform: translate(87.8285vw, 77.106vh) scale(0.4796);
	}
	to {
		transform: translate(89.84335vw, 100vh) scale(0.4796);
	}
}
.snow-bg .snow:nth-child(124) {
	opacity: 0.1808;
	transform: translate(45.0522vw, -10px) scale(0.2105);
	animation: fall-124 20s -15s linear infinite;
}
@keyframes fall-124 {
	74.369% {
		transform: translate(38.3982vw, 74.369vh) scale(0.2105);
	}
	to {
		transform: translate(41.7252vw, 100vh) scale(0.2105);
	}
}
.snow-bg .snow:nth-child(125) {
	opacity: 0.9845;
	transform: translate(54.6332vw, -10px) scale(0.8747);
	animation: fall-125 22s -18s linear infinite;
}
@keyframes fall-125 {
	59.042% {
		transform: translate(46.8823vw, 59.042vh) scale(0.8747);
	}
	to {
		transform: translate(50.75775vw, 100vh) scale(0.8747);
	}
}
.snow-bg .snow:nth-child(126) {
	opacity: 0.6722;
	transform: translate(75.714vw, -10px) scale(0.1882);
	animation: fall-126 16s -28s linear infinite;
}
@keyframes fall-126 {
	69.639% {
		transform: translate(82.3678vw, 69.639vh) scale(0.1882);
	}
	to {
		transform: translate(79.0409vw, 100vh) scale(0.1882);
	}
}
.snow-bg .snow:nth-child(127) {
	opacity: 0.8954;
	transform: translate(76.5275vw, -10px) scale(0.874);
	animation: fall-127 28s -29s linear infinite;
}
@keyframes fall-127 {
	43.475% {
		transform: translate(72.838vw, 43.475vh) scale(0.874);
	}
	to {
		transform: translate(74.68275vw, 100vh) scale(0.874);
	}
}
.snow-bg .snow:nth-child(128) {
	opacity: 0.4524;
	transform: translate(24.3742vw, -10px) scale(0.4945);
	animation: fall-128 18s -17s linear infinite;
}
@keyframes fall-128 {
	54.064% {
		transform: translate(31.2145vw, 54.064vh) scale(0.4945);
	}
	to {
		transform: translate(27.79435vw, 100vh) scale(0.4945);
	}
}
.snow-bg .snow:nth-child(129) {
	opacity: 0.635;
	transform: translate(73.1287vw, -10px) scale(0.0577);
	animation: fall-129 13s -13s linear infinite;
}
@keyframes fall-129 {
	31.517% {
		transform: translate(73.1298vw, 31.517vh) scale(0.0577);
	}
	to {
		transform: translate(73.12925vw, 100vh) scale(0.0577);
	}
}
.snow-bg .snow:nth-child(130) {
	opacity: 0.4371;
	transform: translate(73.4997vw, -10px) scale(0.2573);
	animation: fall-130 19s -20s linear infinite;
}
@keyframes fall-130 {
	76.169% {
		transform: translate(68.0589vw, 76.169vh) scale(0.2573);
	}
	to {
		transform: translate(70.7793vw, 100vh) scale(0.2573);
	}
}
.snow-bg .snow:nth-child(131) {
	opacity: 0.5286;
	transform: translate(20.6545vw, -10px) scale(0.2231);
	animation: fall-131 19s -12s linear infinite;
}
@keyframes fall-131 {
	50.104% {
		transform: translate(29.7309vw, 50.104vh) scale(0.2231);
	}
	to {
		transform: translate(25.1927vw, 100vh) scale(0.2231);
	}
}
.snow-bg .snow:nth-child(132) {
	opacity: 0.2908;
	transform: translate(46.0874vw, -10px) scale(0.0086);
	animation: fall-132 11s -17s linear infinite;
}
@keyframes fall-132 {
	64.647% {
		transform: translate(39.7685vw, 64.647vh) scale(0.0086);
	}
	to {
		transform: translate(42.92795vw, 100vh) scale(0.0086);
	}
}
.snow-bg .snow:nth-child(133) {
	opacity: 0.1684;
	transform: translate(5.4891vw, -10px) scale(0.7148);
	animation: fall-133 29s -17s linear infinite;
}
@keyframes fall-133 {
	62.803% {
		transform: translate(12.4607vw, 62.803vh) scale(0.7148);
	}
	to {
		transform: translate(8.9749vw, 100vh) scale(0.7148);
	}
}
.snow-bg .snow:nth-child(134) {
	opacity: 0.185;
	transform: translate(55.0719vw, -10px) scale(0.877);
	animation: fall-134 25s -20s linear infinite;
}
@keyframes fall-134 {
	39.851% {
		transform: translate(58.8093vw, 39.851vh) scale(0.877);
	}
	to {
		transform: translate(56.9406vw, 100vh) scale(0.877);
	}
}
.snow-bg .snow:nth-child(135) {
	opacity: 0.9671;
	transform: translate(21.9704vw, -10px) scale(0.0642);
	animation: fall-135 30s -2s linear infinite;
}
@keyframes fall-135 {
	37.936% {
		transform: translate(31.6277vw, 37.936vh) scale(0.0642);
	}
	to {
		transform: translate(26.79905vw, 100vh) scale(0.0642);
	}
}
.snow-bg .snow:nth-child(136) {
	opacity: 0.5378;
	transform: translate(81.6953vw, -10px) scale(0.0871);
	animation: fall-136 30s -30s linear infinite;
}
@keyframes fall-136 {
	53.173% {
		transform: translate(81.508vw, 53.173vh) scale(0.0871);
	}
	to {
		transform: translate(81.60165vw, 100vh) scale(0.0871);
	}
}
.snow-bg .snow:nth-child(137) {
	opacity: 0.4726;
	transform: translate(32.075vw, -10px) scale(0.3263);
	animation: fall-137 27s -14s linear infinite;
}
@keyframes fall-137 {
	59.934% {
		transform: translate(41.162vw, 59.934vh) scale(0.3263);
	}
	to {
		transform: translate(36.6185vw, 100vh) scale(0.3263);
	}
}
.snow-bg .snow:nth-child(138) {
	opacity: 0.2451;
	transform: translate(83.6383vw, -10px) scale(0.4728);
	animation: fall-138 23s -23s linear infinite;
}
@keyframes fall-138 {
	42.75% {
		transform: translate(93.043vw, 42.75vh) scale(0.4728);
	}
	to {
		transform: translate(88.34065vw, 100vh) scale(0.4728);
	}
}
.snow-bg .snow:nth-child(139) {
	opacity: 0.207;
	transform: translate(49.9254vw, -10px) scale(0.4671);
	animation: fall-139 30s -29s linear infinite;
}
@keyframes fall-139 {
	79.652% {
		transform: translate(59.3755vw, 79.652vh) scale(0.4671);
	}
	to {
		transform: translate(54.65045vw, 100vh) scale(0.4671);
	}
}
.snow-bg .snow:nth-child(140) {
	opacity: 0.3503;
	transform: translate(50.5883vw, -10px) scale(0.3732);
	animation: fall-140 24s -14s linear infinite;
}
@keyframes fall-140 {
	49.845% {
		transform: translate(58.3923vw, 49.845vh) scale(0.3732);
	}
	to {
		transform: translate(54.4903vw, 100vh) scale(0.3732);
	}
}
.snow-bg .snow:nth-child(141) {
	opacity: 0.7955;
	transform: translate(63.4516vw, -10px) scale(0.2241);
	animation: fall-141 19s -5s linear infinite;
}
@keyframes fall-141 {
	78.486% {
		transform: translate(68.3301vw, 78.486vh) scale(0.2241);
	}
	to {
		transform: translate(65.89085vw, 100vh) scale(0.2241);
	}
}
.snow-bg .snow:nth-child(142) {
	opacity: 0.3645;
	transform: translate(33.7332vw, -10px) scale(0.3125);
	animation: fall-142 13s -30s linear infinite;
}
@keyframes fall-142 {
	54.232% {
		transform: translate(25.8055vw, 54.232vh) scale(0.3125);
	}
	to {
		transform: translate(29.76935vw, 100vh) scale(0.3125);
	}
}
.snow-bg .snow:nth-child(143) {
	opacity: 0.1015;
	transform: translate(61.7908vw, -10px) scale(0.6778);
	animation: fall-143 30s -7s linear infinite;
}
@keyframes fall-143 {
	68.853% {
		transform: translate(68.5813vw, 68.853vh) scale(0.6778);
	}
	to {
		transform: translate(65.18605vw, 100vh) scale(0.6778);
	}
}
.snow-bg .snow:nth-child(144) {
	opacity: 0.5523;
	transform: translate(57.0801vw, -10px) scale(0.1445);
	animation: fall-144 16s -6s linear infinite;
}
@keyframes fall-144 {
	70.368% {
		transform: translate(58.1816vw, 70.368vh) scale(0.1445);
	}
	to {
		transform: translate(57.63085vw, 100vh) scale(0.1445);
	}
}
.snow-bg .snow:nth-child(145) {
	opacity: 0.798;
	transform: translate(12.6467vw, -10px) scale(0.7785);
	animation: fall-145 27s -1s linear infinite;
}
@keyframes fall-145 {
	75.799% {
		transform: translate(5.5551vw, 75.799vh) scale(0.7785);
	}
	to {
		transform: translate(9.1009vw, 100vh) scale(0.7785);
	}
}
.snow-bg .snow:nth-child(146) {
	opacity: 0.4669;
	transform: translate(48.0163vw, -10px) scale(0.8515);
	animation: fall-146 21s -18s linear infinite;
}
@keyframes fall-146 {
	74.391% {
		transform: translate(47.6592vw, 74.391vh) scale(0.8515);
	}
	to {
		transform: translate(47.83775vw, 100vh) scale(0.8515);
	}
}
.snow-bg .snow:nth-child(147) {
	opacity: 0.6302;
	transform: translate(84.109vw, -10px) scale(0.6637);
	animation: fall-147 25s -7s linear infinite;
}
@keyframes fall-147 {
	77.908% {
		transform: translate(92.556vw, 77.908vh) scale(0.6637);
	}
	to {
		transform: translate(88.3325vw, 100vh) scale(0.6637);
	}
}
.snow-bg .snow:nth-child(148) {
	opacity: 0.1949;
	transform: translate(49.9402vw, -10px) scale(0.1956);
	animation: fall-148 12s -29s linear infinite;
}
@keyframes fall-148 {
	46.424% {
		transform: translate(42.3067vw, 46.424vh) scale(0.1956);
	}
	to {
		transform: translate(46.12345vw, 100vh) scale(0.1956);
	}
}
.snow-bg .snow:nth-child(149) {
	opacity: 0.8214;
	transform: translate(98.8529vw, -10px) scale(0.9975);
	animation: fall-149 21s -22s linear infinite;
}
@keyframes fall-149 {
	63.383% {
		transform: translate(90.7927vw, 63.383vh) scale(0.9975);
	}
	to {
		transform: translate(94.8228vw, 100vh) scale(0.9975);
	}
}
.snow-bg .snow:nth-child(150) {
	opacity: 0.1081;
	transform: translate(25.45vw, -10px) scale(0.2879);
	animation: fall-150 14s -27s linear infinite;
}
@keyframes fall-150 {
	37.83% {
		transform: translate(18.9568vw, 37.83vh) scale(0.2879);
	}
	to {
		transform: translate(22.2034vw, 100vh) scale(0.2879);
	}
}
.snow-bg .snow:nth-child(151) {
	opacity: 0.4487;
	transform: translate(50.1487vw, -10px) scale(0.9228);
	animation: fall-151 22s -1s linear infinite;
}
@keyframes fall-151 {
	58.383% {
		transform: translate(58.5437vw, 58.383vh) scale(0.9228);
	}
	to {
		transform: translate(54.3462vw, 100vh) scale(0.9228);
	}
}
.snow-bg .snow:nth-child(152) {
	opacity: 0.2655;
	transform: translate(16.8339vw, -10px) scale(0.4662);
	animation: fall-152 23s -19s linear infinite;
}
@keyframes fall-152 {
	39.725% {
		transform: translate(12.1954vw, 39.725vh) scale(0.4662);
	}
	to {
		transform: translate(14.51465vw, 100vh) scale(0.4662);
	}
}
.snow-bg .snow:nth-child(153) {
	opacity: 0.1679;
	transform: translate(38.0363vw, -10px) scale(0.9888);
	animation: fall-153 21s -22s linear infinite;
}
@keyframes fall-153 {
	53.18% {
		transform: translate(47.2302vw, 53.18vh) scale(0.9888);
	}
	to {
		transform: translate(42.63325vw, 100vh) scale(0.9888);
	}
}
.snow-bg .snow:nth-child(154) {
	opacity: 0.3385;
	transform: translate(8.0229vw, -10px) scale(0.8008);
	animation: fall-154 14s -11s linear infinite;
}
@keyframes fall-154 {
	67.455% {
		transform: translate(8.0726vw, 67.455vh) scale(0.8008);
	}
	to {
		transform: translate(8.04775vw, 100vh) scale(0.8008);
	}
}
.snow-bg .snow:nth-child(155) {
	opacity: 0.6208;
	transform: translate(18.2095vw, -10px) scale(0.9352);
	animation: fall-155 18s -8s linear infinite;
}
@keyframes fall-155 {
	60.76% {
		transform: translate(18.6023vw, 60.76vh) scale(0.9352);
	}
	to {
		transform: translate(18.4059vw, 100vh) scale(0.9352);
	}
}
.snow-bg .snow:nth-child(156) {
	opacity: 0.7005;
	transform: translate(58.5457vw, -10px) scale(0.4931);
	animation: fall-156 26s -13s linear infinite;
}
@keyframes fall-156 {
	74.262% {
		transform: translate(65.8909vw, 74.262vh) scale(0.4931);
	}
	to {
		transform: translate(62.2183vw, 100vh) scale(0.4931);
	}
}
.snow-bg .snow:nth-child(157) {
	opacity: 0.5917;
	transform: translate(91.632vw, -10px) scale(0.8802);
	animation: fall-157 26s -4s linear infinite;
}
@keyframes fall-157 {
	40.754% {
		transform: translate(99.8862vw, 40.754vh) scale(0.8802);
	}
	to {
		transform: translate(95.7591vw, 100vh) scale(0.8802);
	}
}
.snow-bg .snow:nth-child(158) {
	opacity: 0.8744;
	transform: translate(46.129vw, -10px) scale(0.5127);
	animation: fall-158 24s -3s linear infinite;
}
@keyframes fall-158 {
	39.38% {
		transform: translate(53.5771vw, 39.38vh) scale(0.5127);
	}
	to {
		transform: translate(49.85305vw, 100vh) scale(0.5127);
	}
}
.snow-bg .snow:nth-child(159) {
	opacity: 0.7349;
	transform: translate(38.3906vw, -10px) scale(0.9066);
	animation: fall-159 13s -15s linear infinite;
}
@keyframes fall-159 {
	73.972% {
		transform: translate(43.4302vw, 73.972vh) scale(0.9066);
	}
	to {
		transform: translate(40.9104vw, 100vh) scale(0.9066);
	}
}
.snow-bg .snow:nth-child(160) {
	opacity: 0.8923;
	transform: translate(44.3231vw, -10px) scale(0.4261);
	animation: fall-160 19s -13s linear infinite;
}
@keyframes fall-160 {
	46.986% {
		transform: translate(52.5414vw, 46.986vh) scale(0.4261);
	}
	to {
		transform: translate(48.43225vw, 100vh) scale(0.4261);
	}
}
.snow-bg .snow:nth-child(161) {
	opacity: 0.169;
	transform: translate(51.3288vw, -10px) scale(0.3862);
	animation: fall-161 30s -8s linear infinite;
}
@keyframes fall-161 {
	74.252% {
		transform: translate(45.2914vw, 74.252vh) scale(0.3862);
	}
	to {
		transform: translate(48.3101vw, 100vh) scale(0.3862);
	}
}
.snow-bg .snow:nth-child(162) {
	opacity: 0.8049;
	transform: translate(24.3237vw, -10px) scale(0.3285);
	animation: fall-162 23s -8s linear infinite;
}
@keyframes fall-162 {
	78.837% {
		transform: translate(21.2705vw, 78.837vh) scale(0.3285);
	}
	to {
		transform: translate(22.7971vw, 100vh) scale(0.3285);
	}
}
.snow-bg .snow:nth-child(163) {
	opacity: 0.105;
	transform: translate(34.4138vw, -10px) scale(0.3036);
	animation: fall-163 17s -13s linear infinite;
}
@keyframes fall-163 {
	59.407% {
		transform: translate(25.9176vw, 59.407vh) scale(0.3036);
	}
	to {
		transform: translate(30.1657vw, 100vh) scale(0.3036);
	}
}

/*noinspection ALL*/
.snow-bg .snow:nth-child(164) {
	opacity: 0.9983;
	transform: translate(77.984vw, -10px) scale(0.4001);
	animation: fall-164 19s -7s linear infinite;
}
@keyframes fall-164 {
	31.105% {
		transform: translate(73.144vw, 31.105vh) scale(0.4001);
	}
	to {
		transform: translate(75.564vw, 100vh) scale(0.4001);
	}
}
.snow-bg .snow:nth-child(165) {
	opacity: 0.1804;
	transform: translate(23.3456vw, -10px) scale(0.2181);
	animation: fall-165 16s -4s linear infinite;
}
@keyframes fall-165 {
	49.074% {
		transform: translate(13.9352vw, 49.074vh) scale(0.2181);
	}
	to {
		transform: translate(18.6404vw, 100vh) scale(0.2181);
	}
}
.snow-bg .snow:nth-child(166) {
	opacity: 0.6804;
	transform: translate(56.6837vw, -10px) scale(0.5826);
	animation: fall-166 29s -5s linear infinite;
}
@keyframes fall-166 {
	35.261% {
		transform: translate(60.9334vw, 35.261vh) scale(0.5826);
	}
	to {
		transform: translate(58.80855vw, 100vh) scale(0.5826);
	}
}
.snow-bg .snow:nth-child(167) {
	opacity: 0.8758;
	transform: translate(99.4819vw, -10px) scale(0.5516);
	animation: fall-167 12s -26s linear infinite;
}
@keyframes fall-167 {
	50.921% {
		transform: translate(94.7065vw, 50.921vh) scale(0.5516);
	}
	to {
		transform: translate(97.0942vw, 100vh) scale(0.5516);
	}
}
.snow-bg .snow:nth-child(168) {
	opacity: 0.8351;
	transform: translate(64.9639vw, -10px) scale(0.6931);
	animation: fall-168 17s -13s linear infinite;
}
@keyframes fall-168 {
	35.874% {
		transform: translate(60.6677vw, 35.874vh) scale(0.6931);
	}
	to {
		transform: translate(62.8158vw, 100vh) scale(0.6931);
	}
}
.snow-bg .snow:nth-child(169) {
	opacity: 0.0023;
	transform: translate(90.4658vw, -10px) scale(0.4163);
	animation: fall-169 20s -30s linear infinite;
}
@keyframes fall-169 {
	70.32% {
		transform: translate(92.2279vw, 70.32vh) scale(0.4163);
	}
	to {
		transform: translate(91.34685vw, 100vh) scale(0.4163);
	}
}
.snow-bg .snow:nth-child(170) {
	opacity: 0.8003;
	transform: translate(63.9866vw, -10px) scale(0.5369);
	animation: fall-170 23s -26s linear infinite;
}
@keyframes fall-170 {
	67.259% {
		transform: translate(70.1718vw, 67.259vh) scale(0.5369);
	}
	to {
		transform: translate(67.0792vw, 100vh) scale(0.5369);
	}
}
.snow-bg .snow:nth-child(171) {
	opacity: 0.6315;
	transform: translate(54.048vw, -10px) scale(0.405);
	animation: fall-171 29s -6s linear infinite;
}
@keyframes fall-171 {
	42.551% {
		transform: translate(50.8272vw, 42.551vh) scale(0.405);
	}
	to {
		transform: translate(52.4376vw, 100vh) scale(0.405);
	}
}
.snow-bg .snow:nth-child(172) {
	opacity: 0.0731;
	transform: translate(1.8661vw, -10px) scale(0.5164);
	animation: fall-172 10s -30s linear infinite;
}
@keyframes fall-172 {
	76.645% {
		transform: translate(-6.2047vw, 76.645vh) scale(0.5164);
	}
	to {
		transform: translate(-2.1693vw, 100vh) scale(0.5164);
	}
}
.snow-bg .snow:nth-child(173) {
	opacity: 0.8069;
	transform: translate(20.3156vw, -10px) scale(0.3444);
	animation: fall-173 13s -15s linear infinite;
}
@keyframes fall-173 {
	37.47% {
		transform: translate(24.7058vw, 37.47vh) scale(0.3444);
	}
	to {
		transform: translate(22.5107vw, 100vh) scale(0.3444);
	}
}
.snow-bg .snow:nth-child(174) {
	opacity: 0.1114;
	transform: translate(62.5154vw, -10px) scale(0.5939);
	animation: fall-174 23s -7s linear infinite;
}
@keyframes fall-174 {
	66.021% {
		transform: translate(55.901vw, 66.021vh) scale(0.5939);
	}
	to {
		transform: translate(59.2082vw, 100vh) scale(0.5939);
	}
}
.snow-bg .snow:nth-child(175) {
	opacity: 0.5551;
	transform: translate(76.8259vw, -10px) scale(0.1592);
	animation: fall-175 22s -28s linear infinite;
}
@keyframes fall-175 {
	69.651% {
		transform: translate(80.9137vw, 69.651vh) scale(0.1592);
	}
	to {
		transform: translate(78.8698vw, 100vh) scale(0.1592);
	}
}
.snow-bg .snow:nth-child(176) {
	opacity: 0.5853;
	transform: translate(95.0992vw, -10px) scale(0.8853);
	animation: fall-176 14s -6s linear infinite;
}
@keyframes fall-176 {
	74.604% {
		transform: translate(86.0264vw, 74.604vh) scale(0.8853);
	}
	to {
		transform: translate(90.5628vw, 100vh) scale(0.8853);
	}
}
.snow-bg .snow:nth-child(177) {
	opacity: 0.2771;
	transform: translate(33.472vw, -10px) scale(0.916);
	animation: fall-177 29s -22s linear infinite;
}
@keyframes fall-177 {
	47.839% {
		transform: translate(41.9595vw, 47.839vh) scale(0.916);
	}
	to {
		transform: translate(37.71575vw, 100vh) scale(0.916);
	}
}
.snow-bg .snow:nth-child(178) {
	opacity: 0.5225;
	transform: translate(55.4472vw, -10px) scale(0.228);
	animation: fall-178 14s -11s linear infinite;
}
@keyframes fall-178 {
	63.42% {
		transform: translate(53.9441vw, 63.42vh) scale(0.228);
	}
	to {
		transform: translate(54.69565vw, 100vh) scale(0.228);
	}
}
.snow-bg .snow:nth-child(179) {
	opacity: 0.3787;
	transform: translate(47.3578vw, -10px) scale(0.4284);
	animation: fall-179 12s -3s linear infinite;
}
@keyframes fall-179 {
	64.275% {
		transform: translate(56.8855vw, 64.275vh) scale(0.4284);
	}
	to {
		transform: translate(52.12165vw, 100vh) scale(0.4284);
	}
}
.snow-bg .snow:nth-child(180) {
	opacity: 0.0733;
	transform: translate(82.7493vw, -10px) scale(0.043);
	animation: fall-180 24s -7s linear infinite;
}
@keyframes fall-180 {
	65.736% {
		transform: translate(87.7196vw, 65.736vh) scale(0.043);
	}
	to {
		transform: translate(85.23445vw, 100vh) scale(0.043);
	}
}
.snow-bg .snow:nth-child(181) {
	opacity: 0.52;
	transform: translate(44.895vw, -10px) scale(0.3243);
	animation: fall-181 13s -25s linear infinite;
}
@keyframes fall-181 {
	34.913% {
		transform: translate(41.4805vw, 34.913vh) scale(0.3243);
	}
	to {
		transform: translate(43.18775vw, 100vh) scale(0.3243);
	}
}
.snow-bg .snow:nth-child(182) {
	opacity: 0.0868;
	transform: translate(85.0418vw, -10px) scale(0.3534);
	animation: fall-182 14s -26s linear infinite;
}
@keyframes fall-182 {
	64.563% {
		transform: translate(80.535vw, 64.563vh) scale(0.3534);
	}
	to {
		transform: translate(82.7884vw, 100vh) scale(0.3534);
	}
}
.snow-bg .snow:nth-child(183) {
	opacity: 0.3148;
	transform: translate(20.2783vw, -10px) scale(0.6658);
	animation: fall-183 10s -7s linear infinite;
}
@keyframes fall-183 {
	64.323% {
		transform: translate(17.2033vw, 64.323vh) scale(0.6658);
	}
	to {
		transform: translate(18.7408vw, 100vh) scale(0.6658);
	}
}
.snow-bg .snow:nth-child(184) {
	opacity: 0.7342;
	transform: translate(11.0813vw, -10px) scale(0.8022);
	animation: fall-184 28s -29s linear infinite;
}
@keyframes fall-184 {
	62.03% {
		transform: translate(1.0814vw, 62.03vh) scale(0.8022);
	}
	to {
		transform: translate(6.08135vw, 100vh) scale(0.8022);
	}
}
.snow-bg .snow:nth-child(185) {
	opacity: 0.2924;
	transform: translate(75.091vw, -10px) scale(0.4344);
	animation: fall-185 13s -21s linear infinite;
}
@keyframes fall-185 {
	77.376% {
		transform: translate(71.2131vw, 77.376vh) scale(0.4344);
	}
	to {
		transform: translate(73.15205vw, 100vh) scale(0.4344);
	}
}
.snow-bg .snow:nth-child(186) {
	opacity: 0.7705;
	transform: translate(95.1285vw, -10px) scale(0.0671);
	animation: fall-186 19s -29s linear infinite;
}
@keyframes fall-186 {
	46.58% {
		transform: translate(86.0976vw, 46.58vh) scale(0.0671);
	}
	to {
		transform: translate(90.61305vw, 100vh) scale(0.0671);
	}
}
.snow-bg .snow:nth-child(187) {
	opacity: 0.3381;
	transform: translate(43.4208vw, -10px) scale(0.9779);
	animation: fall-187 22s -1s linear infinite;
}
@keyframes fall-187 {
	46.205% {
		transform: translate(36.1329vw, 46.205vh) scale(0.9779);
	}
	to {
		transform: translate(39.77685vw, 100vh) scale(0.9779);
	}
}
.snow-bg .snow:nth-child(188) {
	opacity: 0.8098;
	transform: translate(35.3636vw, -10px) scale(0.1171);
	animation: fall-188 19s -27s linear infinite;
}
@keyframes fall-188 {
	34.648% {
		transform: translate(39.2315vw, 34.648vh) scale(0.1171);
	}
	to {
		transform: translate(37.29755vw, 100vh) scale(0.1171);
	}
}
.snow-bg .snow:nth-child(189) {
	opacity: 0.9893;
	transform: translate(84.3474vw, -10px) scale(0.1797);
	animation: fall-189 14s -26s linear infinite;
}
@keyframes fall-189 {
	44.16% {
		transform: translate(93.1803vw, 44.16vh) scale(0.1797);
	}
	to {
		transform: translate(88.76385vw, 100vh) scale(0.1797);
	}
}
.snow-bg .snow:nth-child(190) {
	opacity: 0.2211;
	transform: translate(34.538vw, -10px) scale(0.0674);
	animation: fall-190 26s -11s linear infinite;
}
@keyframes fall-190 {
	73.324% {
		transform: translate(26.5153vw, 73.324vh) scale(0.0674);
	}
	to {
		transform: translate(30.52665vw, 100vh) scale(0.0674);
	}
}
.snow-bg .snow:nth-child(191) {
	opacity: 0.5497;
	transform: translate(48.0778vw, -10px) scale(0.5628);
	animation: fall-191 10s -10s linear infinite;
}
@keyframes fall-191 {
	57.34% {
		transform: translate(43.6874vw, 57.34vh) scale(0.5628);
	}
	to {
		transform: translate(45.8826vw, 100vh) scale(0.5628);
	}
}
.snow-bg .snow:nth-child(192) {
	opacity: 0.2013;
	transform: translate(4.5523vw, -10px) scale(0.829);
	animation: fall-192 19s -21s linear infinite;
}
@keyframes fall-192 {
	63.61% {
		transform: translate(-2.9706vw, 63.61vh) scale(0.829);
	}
	to {
		transform: translate(0.79085vw, 100vh) scale(0.829);
	}
}
.snow-bg .snow:nth-child(193) {
	opacity: 0.7314;
	transform: translate(59.4514vw, -10px) scale(0.5018);
	animation: fall-193 25s -13s linear infinite;
}
@keyframes fall-193 {
	64.199% {
		transform: translate(63.8174vw, 64.199vh) scale(0.5018);
	}
	to {
		transform: translate(61.6344vw, 100vh) scale(0.5018);
	}
}
.snow-bg .snow:nth-child(194) {
	opacity: 0.4276;
	transform: translate(84.0348vw, -10px) scale(0.9819);
	animation: fall-194 12s -18s linear infinite;
}
@keyframes fall-194 {
	34.296% {
		transform: translate(74.7088vw, 34.296vh) scale(0.9819);
	}
	to {
		transform: translate(79.3718vw, 100vh) scale(0.9819);
	}
}
.snow-bg .snow:nth-child(195) {
	opacity: 0.4649;
	transform: translate(9.2227vw, -10px) scale(0.6172);
	animation: fall-195 25s -2s linear infinite;
}
@keyframes fall-195 {
	43.932% {
		transform: translate(7.742vw, 43.932vh) scale(0.6172);
	}
	to {
		transform: translate(8.48235vw, 100vh) scale(0.6172);
	}
}
.snow-bg .snow:nth-child(196) {
	opacity: 0.8204;
	transform: translate(25.5267vw, -10px) scale(0.8195);
	animation: fall-196 23s -13s linear infinite;
}
@keyframes fall-196 {
	58.194% {
		transform: translate(33.427vw, 58.194vh) scale(0.8195);
	}
	to {
		transform: translate(29.47685vw, 100vh) scale(0.8195);
	}
}
.snow-bg .snow:nth-child(197) {
	opacity: 0.7986;
	transform: translate(7.9473vw, -10px) scale(0.5496);
	animation: fall-197 25s -25s linear infinite;
}
@keyframes fall-197 {
	78.094% {
		transform: translate(0.5857vw, 78.094vh) scale(0.5496);
	}
	to {
		transform: translate(4.2665vw, 100vh) scale(0.5496);
	}
}
.snow-bg .snow:nth-child(198) {
	opacity: 0.4165;
	transform: translate(28.0918vw, -10px) scale(0.1363);
	animation: fall-198 24s -16s linear infinite;
}
@keyframes fall-198 {
	35.656% {
		transform: translate(22.1237vw, 35.656vh) scale(0.1363);
	}
	to {
		transform: translate(25.10775vw, 100vh) scale(0.1363);
	}
}
.snow-bg .snow:nth-child(199) {
	opacity: 0.1738;
	transform: translate(93.124vw, -10px) scale(0.8649);
	animation: fall-199 15s -22s linear infinite;
}
@keyframes fall-199 {
	60.888% {
		transform: translate(86.1519vw, 60.888vh) scale(0.8649);
	}
	to {
		transform: translate(89.63795vw, 100vh) scale(0.8649);
	}
}
.snow-bg .snow:nth-child(200) {
	opacity: 0.5072;
	transform: translate(45.607vw, -10px) scale(0.7827);
	animation: fall-200 15s -3s linear infinite;
}
@keyframes fall-200 {
	67.012% {
		transform: translate(54.9314vw, 67.012vh) scale(0.7827);
	}
	to {
		transform: translate(50.2692vw, 100vh) scale(0.7827);
	}
}
